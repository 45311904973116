import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import { Button } from "@/shadcn/ui/button";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import { Icon } from "@iconify/react";
import { User } from "@/models/idm/User";
import RoleService from "@/services/idm/RoleService";
import UserService from "@/services/idm/UserService";
import { Checkbox } from "@/shadcn/ui/checkbox";
import { UIPageField, UIPageModel, UIPageType } from "@/models/uipage/UIPageModel";
import UIPageService from "@/services/ui/UIPageService";
import DevClass from "../comps/DevClass";
import MenuSelect from "../comps/MenuSelect";
import RoleSelect from "../comps/RoleSelect";
import ModuleSelect from "../comps/ModuleSelect";
import { SearchHelp } from "../comps/SearchHelp";
import PageTypeSelect from "../comps/PageTypeSelect";
import FieldTypeSelect from "../comps/FieldTypeSelect";
import Tile from "../comps/Tile";
import clsx from "clsx";
import { truncate } from "fs";
//
function DisplayPageDetail() {
  const navigate = useNavigate();

  const routeParams = useParams();
  console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/pages");  
            }}
          >
            Pages
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Display Page</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialPage: UIPageModel = {
    UICode: routeParams.pagename ? routeParams.pagename : "",
    Title: "",
    SubTitle: "",
    UIPath: "",
    UIPageType:UIPageType.Screen,
    BGClassName:"",
    FigureClassName:"",
    TileClassName:"bg-yellow-100 text-blue-400",
    TileIconName: "oui:app-reporting",
    TileIconClassName: "",
    TextClassName : "",
    Seqnr : 99,
    IsActive: true,
    IsMobile: true,
    ShowAsTile: true,
    Fields: [],
    Statuses: [],
    Params: [],
    Words: []

  };

  for (let index = 0; index < 10; index++) {
    InitialPage.Fields.push({
      FieldName: "",
      RollName: "",
      Enabled: true,
      FieldType: "",
      ParentFieldName: "",
      PostBack: false,
      Required: false,
      Seqnr: index,
      Visible: true,
      ClassName: "",

    });
  }

  const [PageObject, SetPage] = useState(InitialPage);
  const [loaded, setLoaded] = useState(false);
  const [canbeChanged, setCanBeChanged] = useState(false);

  useEffect(() => {
    setLoaded(false);
    fetch();
    setLoaded(true);
  }, []);

  const fetch = async () => {
    if (routeParams.pagename) {
      const resp = await UIPageService.getByName(routeParams.pagename);
      if (resp) {
        if (resp) {
          SetPage(resp);
        } else {
          debugger;
        }
      }
    }
  };

  const PropChanged = (fname: string, val: any) => {
    if (fname === "Title") {
      let newobj: UIPageModel = { ...PageObject, Title: val };
      SetPage(newobj);
    }     else if (fname === "SubTitle") {
      let newobj: UIPageModel = { ...PageObject, SubTitle: val };
      SetPage(newobj);
    }   else if (fname === "UIPath") {
      let newobj: UIPageModel = { ...PageObject, UIPath: val };
      SetPage(newobj);
    } else if (fname === "TileIconName") {
      let newobj: UIPageModel = { ...PageObject, TileIconName: val };
      SetPage(newobj);
    }
    else if (fname === "IsActive") {
      let newobj: UIPageModel = { ...PageObject, IsActive: val };
      SetPage(newobj);
    } else if (fname === "IsMobile") {
      let newobj: UIPageModel = { ...PageObject, IsMobile: val };
      SetPage(newobj);
    } else if (fname === "TileClassName") {
      let newobj: UIPageModel = { ...PageObject, TileClassName: val };
      SetPage(newobj);
    } else if (fname === "TileIconClassName") {
      let newobj: UIPageModel = { ...PageObject, TileIconClassName: val };
      SetPage(newobj);
    } else if (fname === "MenuCode") {
      let newobj: UIPageModel = { ...PageObject, MenuCode: val };
      SetPage(newobj);
    } else if (fname === "ModuleCode") {
      let newobj: UIPageModel = { ...PageObject, ModuleCode: val };
      SetPage(newobj);
    } else if (fname === "RoleName") {
      let newobj: UIPageModel = { ...PageObject, RoleName: val };
      SetPage(newobj);
    } else if (fname === "ProgName") {
      let newobj: UIPageModel = { ...PageObject, ProgName: val };
      SetPage(newobj);
    } else if (fname === "ShowAsTile") {
      let newobj: UIPageModel = { ...PageObject, ShowAsTile: val };
      SetPage(newobj);
    } else if (fname === "UIPageType") {
      let newobj: UIPageModel = { ...PageObject, UIPageType: val };
      SetPage(newobj);
    } else if (fname === "BGClassName") {
      let newobj: UIPageModel = { ...PageObject, BGClassName: val };
      SetPage(newobj);
    }
  };

  const FieldListChanged = (fname: string, ind: number, val: any) => {
    debugger;
    if (fname === "FieldName") {
      let newobj: UIPageModel = { ...PageObject };
      newobj.Fields[ind].FieldName = val;
      SetPage(newobj);
    } else if (fname === "FieldType") {
      let newobj: UIPageModel = { ...PageObject };
      newobj.Fields[ind].FieldType = val;
      SetPage(newobj);
    } else if (fname === "ClassName") {
      let newobj: UIPageModel = { ...PageObject };
      newobj.Fields[ind].ClassName = val;
      SetPage(newobj);
    } else if (fname === "ParentFieldName") {
      let newobj: UIPageModel = { ...PageObject };
      newobj.Fields[ind].ParentFieldName = val;
      SetPage(newobj);
    } else if (fname === "RollName") {
      let newobj: UIPageModel = { ...PageObject };
      newobj.Fields[ind].RollName = val;
      SetPage(newobj);
    } else if (fname === "Enabled") {
      let newobj: UIPageModel = { ...PageObject };
      newobj.Fields[ind].Enabled = val;
      SetPage(newobj);
    } else if (fname === "Required") {
      let newobj: UIPageModel = { ...PageObject };
      newobj.Fields[ind].Required = val;
      SetPage(newobj);
    } else if (fname === "Visible") {
      let newobj: UIPageModel = { ...PageObject };
      newobj.Fields[ind].Visible = val;
      SetPage(newobj);
    } 
  };

  const OnRollNameSelect = async (x: string, ind: number) => {
    let newobj: UIPageModel = { ...PageObject };
    newobj.Fields[ind].RollName = x;

    // var delData = await DataElementService.getByName(x);
    // if (delData) {
    //   newobj.Fields[ind].Domname = delData.DomName;
    //   newobj.Fields[ind].Datatype = delData.Datatype;
    //   newobj.Fields[ind].Definition = delData.Definition;
    //   newobj.Fields[ind].Length = delData.Length;
    // }

    SetPage(newobj);
  };

  const OnRollNameBlur = async (x: string, ind: number) => {
    let newobj: UIPageModel = { ...PageObject };
    newobj.Fields[ind].RollName = x;
    debugger;
    // if (x === "") {
    //   newobj.Columns[ind].Domname = "";
    //   newobj.Columns[ind].Datatype = "";
    //   newobj.Columns[ind].Definition = "";
    //   newobj.Columns[ind].Length = 0;
    //   SetPage(newobj);
    //   return;
    // }
    // var delData = await DataElementService.getByName(x);
    // if (delData) {
    //   newobj.Columns[ind].Domname = delData.DomName;
    //   newobj.Columns[ind].Datatype = delData.Datatype;
    //   newobj.Columns[ind].Definition = delData.Definition;
    //   newobj.Columns[ind].Length = delData.Length;
    // } else {
    //   newobj.Columns[ind].Domname = "";
    //   newobj.Columns[ind].Datatype = "";
    //   newobj.Columns[ind].Definition = "";
    //   newobj.Columns[ind].Length = 0;
    // }

    SetPage(newobj);
  };




  /*
    Saves
  */
  const Save = async () => {
    debugger;
    setLoaded(false);
    let newSaveobj: UIPageModel = { ...PageObject, Fields: [] };

    PageObject.Fields.forEach((field) => {
      if (field.FieldName) {
        newSaveobj.Fields.push({
          FieldName: field.FieldName,
          RollName: field.RollName,
          Enabled: field.Enabled,
          FieldType: field.FieldType,
          ParentFieldName: field.ParentFieldName,
          PostBack: field.PostBack,
          Required: field.Required,
          Seqnr: field.Seqnr,
          Visible: field.Visible,

        });
      }
    });

    const resp = await UIPageService.put(newSaveobj);
    if (resp.data) {
      //navigate("/apps/ddic");
      let newSaveobj1: UIPageModel = { ...newSaveobj };
      SetPage(newSaveobj1);
      toast({
        title: "Successfull:)",
        description: "Page has been created.",
      });
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }

    setLoaded(true);
  };

  const OnProgSelect = (x: string) => {
    let newobj: UIPageModel = { ...PageObject };
    newobj.ProgName = x;
    SetPage(newobj);
  };

  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">Display Page</h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      <div className="flex flex-row flex-nowrap my-2 gap-1">
      </div>

      <div className="flex flex-col w-full gap-2">
       <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center">
            <Label className="w-48">Page Code</Label>
            <Input
              className="bg-gray-100 w-40 uppercase me-2"
              placeholder="?"
              value={PageObject.UICode}
              maxLength={50}
              disabled={true}
              onChange={(e) => PropChanged("UICode", e.target.value)}
            />
          <PageTypeSelect Value={PageObject.UIPageType ? PageObject.UIPageType : 0} 
                  Disabled={true}
                  />

          </div>
          <div className="flex flex-row items-center">
          <Label className="w-48">URL Path</Label>
          <Input
              className="bg-gray-100 w-72"
              placeholder="/apps/exec/..."
              //value={"/apps/exec/" + PageObject.UICode.toLowerCase().toString()}
              value={PageObject.UIPath}
              maxLength={60}
              disabled={true}
            ></Input>
          </div>
          <div className="flex flex-row items-center">
            <Label className="w-48">Title</Label>
            <Input
              className="bg-gray-100 w-64"
              placeholder="?"
              value={PageObject.Title}
              maxLength={60}
              disabled={true}
              
            ></Input>
          </div>
          <div className="flex flex-row items-center">
            <Label className="w-48">Sub Title</Label>
            <Input
              className="bg-gray-100 w-96"
              placeholder=""
              value={PageObject.SubTitle}
              maxLength={60}
              disabled={true}
            ></Input>
          </div>
        </div>
        {/* tile part */}
        <div>
          <Tile
                  FieldName={"sampleTile"}
                  Title={PageObject.Title}
                  SubTitle={PageObject.SubTitle}
                  IconName={PageObject.TileIconName}
                  ClassName={PageObject.TileClassName}
                  IconClassName={PageObject.TileIconClassName}
                  NavigateTo={""}
                  Figure={"2"}
                />
        </div>

       </div>
       




        {/* Tab Start */}
        <Tabs defaultValue="properties" className="md:w-2/3 mt-5">
          <TabsList>
            <TabsTrigger value="properties">Properties</TabsTrigger>
            <TabsTrigger value="fields">Fields</TabsTrigger>
            <TabsTrigger value="statuses">Statuses</TabsTrigger>
            <TabsTrigger value="params">Parameters</TabsTrigger>
            <TabsTrigger value="words">Words</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Properties */}
          <TabsContent value="properties" className="flex flex-col gap-2">

              <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Program/Role</Label>
                <SearchHelp
                  SHName="PROGNAME"
                  disabled={true}
                  onChange={(e) => PropChanged("ProgName", e.target.value)}
                  value={PageObject.ProgName ? PageObject.ProgName : ""}
                  OnSelect={(x) => OnProgSelect(x)}
                />
                              <RoleSelect Value={PageObject.RoleName ? PageObject.RoleName : ""} 
                Disabled={true}
                OnSelect={(e) => PropChanged("RoleName", e)}
                />
            </div>
            <div className="flex flex-row items-center gap-2">
            <Label className="w-32">Menu/Order</Label>
              <MenuSelect Value={PageObject.MenuCode ? PageObject.MenuCode : ""} 
                Disabled={true}
                />
              <Input
                className="bg-gray-50 w-40"
                type="number"
                value={PageObject.Seqnr}
                disabled={true}
              ></Input>
            </div>
             {/* ModuleCode */}
            {/* <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Module</Label>
              <ModuleSelect Value={PageObject.ModuleCode ? PageObject.ModuleCode : ""} 
                Disabled={false}
                
                OnSelect={(e) => PropChanged("ModuleCode", e)}
                />
            </div> */}

            <div className="flex flex-row items-center gap-2">
            <Label className="w-32">BG/Title/Icon Cls</Label>
              <Input
                className="bg-gray-50 w-32"
                placeholder="bg classes"
                value={PageObject.BGClassName}
                maxLength={50}
                disabled={true}
              ></Input>
              <Input
                className="bg-gray-50 w-32"
                placeholder="title classes"
                value={PageObject.PageTitleClassName}
                maxLength={50}
                disabled={true}
              ></Input>
              <Input
                className="bg-gray-50 w-32"
                placeholder="info icon classes"
                value={PageObject.PageInfoIconsClassName}
                maxLength={50}
                disabled={true}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
            <div className="w-32 flex flex-row">
                <span>Tile/Text/Figure Class</span> 
                  <a href="https://tailwindcss.com/docs/customizing-colors" className="text-blue-800" target="_blank">
                    <Icon icon="material-symbols:info-outline" className="" width="14" height="14" />
                  </a>
              </div>
              <Input
                className="bg-gray-50 w-32"
                placeholder=""
                value={PageObject.TileClassName}
                maxLength={100}
                disabled={true}
              ></Input>
                            <Input
                className="bg-gray-50 w-32"
                placeholder=""
                value={PageObject.TextClassName}
                maxLength={100}
                disabled={true}
              ></Input>
                                          <Input
                className="bg-gray-50 w-32"
                placeholder=""
                value={PageObject.FigureClassName}
                maxLength={100}
                disabled={true}
              ></Input>
            </div>
              {/* IconName */}
              <div className="flex flex-row items-center gap-2">
              <div className="w-32 flex flex-row">
                <span>Tile Icon/Class</span> 
                <a href="https://icon-sets.iconify.design/" className="text-blue-800" target="_blank">
                  <Icon icon="material-symbols:info-outline" className="" width="14" height="14" />
                </a>
              
              
              </div>

              <Input
                className="bg-gray-100 w-64"
                placeholder=""
                value={PageObject.TileIconName}
                maxLength={50}
                disabled={true}
              ></Input>
                            <Input
                className="bg-gray-100 w-64"
                placeholder=""
                value={PageObject.TileIconClassName}
                maxLength={50}
                disabled={true}
              ></Input>
            </div>
            {/* IsActive */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Active?</Label>
              <Checkbox
                checked={PageObject.IsActive}
                disabled={true}
                />
            </div>
            {/* IsMobile */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Mobile?</Label>
              <Checkbox
                checked={PageObject.IsMobile}
                disabled={true}
                />
            </div>
            {/* ShowAsTile */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Show as   Tile</Label>
              <Checkbox
                checked={PageObject.ShowAsTile}
                disabled={true}
                />
            </div>

          </TabsContent>
          {/* Tab 2 : Fields */}
          <TabsContent value="fields" className="flex flex-col gap-2">
          <table className="w-full">
              <thead>
                <tr className="font-unbold text-left">
                  <th></th>
                  <th>Field Type</th>
                  <th>Field Name</th>
                  <th>DE</th>
                  <th>ParId</th>
                  <th>E</th>
                  <th>R</th>
                  <th>V</th>
                  <th>P</th>
                  <th>ClassName/Props/Data</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Fields.map((fld, index) => (
                  <tr>
                  <td className="flex flex-row items-center h-8">
                      <div title="Add blank row up" className="h-3 hover:cursor-pointer"><Icon className="text-green-600" icon="mdi:table-row-add-before" width={18} height={18} /></div>
                      <div title="Add blank row down" className="h-3 hover:cursor-pointer"><Icon className="text-green-600" icon="mdi:table-row-add-after" width={18} height={18} /></div>
                      <div title="Remove row" className="h-3 hover:cursor-pointer"><Icon className="text-red-600" icon="mingcute:minus-circle-fill" width={18} height={18} /></div>
                      <div title="Move row up" className="h-3 hover:cursor-pointer"><Icon className="text-blue-800" icon="bx:up-arrow" width={18} height={18} /></div>
                      <div title="Move row down" className="h-3 hover:cursor-pointer"><Icon className="text-blue-800" icon="bx:down-arrow" width={18} height={18} /></div>
                    </td>
                    <td className="w-[100px]">
                      {/* FieldName */}
                      <Input
                        title="h1, h2, h3, h4, p, textbox, select, date, time, textarea, tile, div, hstack, vstack"
                        className="bg-gray-50"
                        placeholder=""
                        value={fld.FieldType}
                        disabled={true}
                      ></Input>
                    </td>
                    <td className="w-[100px]">
                      {/* FieldName */}
                      <Input
                        className="bg-gray-50"
                        placeholder=""
                        value={fld.FieldName}
                        disabled={true}
                      ></Input>
                    </td>
                    <td className="w-[50px]">
                      <SearchHelp
                        SHName="ROLLNAME"
                        value={fld.RollName}
                        InputClassName="uppercase"
                        disabled={true}
                      />
                    </td>
                    <td className="w-[80px]">
                      {/* ParentFieldName */}
                      <Input
                        className="bg-gray-50"
                        placeholder=""
                        value={fld.ParentFieldName}
                        disabled={true}

                      ></Input>
                    </td>
                    <td className="w-[20px]">
                      <Checkbox
                         title="Enabled?"
                        checked={fld.Enabled}
                        disabled={true} 
                      />
                    </td>
                    <td className="w-[20px]">
                      <Checkbox
                        title="Required?"
                        checked={fld.Required}
                        disabled={true}
                      />
                    </td>
                    <td className="w-[20px]">
                      <Checkbox
                        title="Visible element"
                        checked={fld.Visible}
                        disabled={true}
                      />
                    </td>
                    <td className="w-[20px]">
                      <Checkbox
                        title="Postback? when any change occurs in element, server side will be triggered"
                        checked={fld.PostBack}
                        disabled={true}
                      />
                    </td>
                    <td className="w-fill">
                      <div className="flex flex-row gap-0 items-center justify-center">
                     
                      {/* ClassName */}

                      <Input
                        title="Tailwind CSS classnames"
                        className="bg-gray-50"
                        placeholder="CSS class"
                        value={fld.ClassName}
                        disabled={true}
                      ></Input>
                      <Input
                        title="Properties, prop1=value1,prop2=value2, prop3=value3"
                        className="bg-white"
                        placeholder="Props"
                        value={fld.Props}
                        disabled={false}
                        onChange={(e) =>
                          FieldListChanged("Props", index, e.target.value)
                        }
                      ></Input>
                       <Input
                         title="Data object for element, read doc for fieldtype"
                        className="bg-gray-50"
                        placeholder="Data"
                        value={fld.Data}
                        disabled={true}

                      ></Input>
                         </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabsContent>
          {/* Tab 3 : Statuses */}
          <TabsContent value="statuses" className="flex flex-col gap-2">
          </TabsContent>
          {/* Tab 4 : Params */}
          <TabsContent value="params" className="flex flex-col gap-2 mt-0">
            {PageObject.Params.map((item, index) => (
                <div className="flex flex-row items-center gap-1">
                  <div className="flex flex-row gap-0 items-center justify-center">
                    <div className="h-3 hover:cursor-pointer">  <Icon className="text-green-600" icon="mingcute:add-circle-fill" width={22} height={22} /></div>
                    <div className="h-3 hover:cursor-pointer"><Icon className="text-red-600" icon="mingcute:minus-circle-fill" width={22} height={22} /></div>
                    {/* <div className="h-3">+</div> */}
                  </div>
                  <Input
                    className="bg-gray-50 w-36 uppercase"
                    placeholder=""
                    value={item.Name}
                    maxLength={50}
                    disabled={true}
                  ></Input>
                  <Input
                    className="bg-gray-50 w-96 uppercase"
                    placeholder=""
                    value={item.Value}
                    maxLength={200}
                    disabled={true}
                  ></Input>
                </div>
              ))}
          </TabsContent>
          {/* Tab 5 : Words */}
          <TabsContent value="words" className="flex flex-col gap-2 mt-0">
            {PageObject.Words.map((item, index) => (
                <div className="flex flex-row items-center gap-1">
                  <div className="flex flex-row gap-0 items-center justify-center">
                    <div className="h-3 hover:cursor-pointer">  <Icon className="text-green-600" icon="mingcute:add-circle-fill" width={22} height={22} /></div>
                    <div className="h-3 hover:cursor-pointer"><Icon className="text-red-600" icon="mingcute:minus-circle-fill" width={22} height={22} /></div>
                    {/* <div className="h-3">+</div> */}
                  </div>
                  <Input
                    className="bg-gray-50 w-36 uppercase"
                    placeholder=""
                    value={item.WordName}
                    maxLength={50}
                    disabled={true}
                  ></Input>
                  <Input
                    className="bg-gray-50 w-96"
                    placeholder=""
                    value={item.WordText}
                    maxLength={200}
                    disabled={true}
                  ></Input>
                </div>
              ))}
          </TabsContent>
        </Tabs>
        <Toaster />
      </div>
      {/* {JSON.stringify(PageObject)} */}
    </div>
  );
}

export default DisplayPageDetail;
