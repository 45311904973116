//
// https://github.com/shadcn-ui/ui/blob/main/apps/www/app/(app)/examples/mail/components/mail.tsx
//

import * as React from "react"
import {
  AlertCircle,
  Archive,
  ArchiveX,
  File,
  Inbox,
  MessagesSquare,
  Search,
  Send,
  ShoppingCart,
  Trash2,
  Users2,
} from "lucide-react"

import { cn } from "@/lib/utils"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/shadcn/ui/resizable"
import { Separator } from "@/shadcn/ui/separator"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs"
import { TooltipProvider } from "@/shadcn/ui/tooltip"
import { Nav } from "./Nav"
import { useMail } from "./useMail"
import { MailList } from "./MailList"
import { Input } from "@/shadcn/ui/input"
import { MailDisplay } from "./MailDisplay"
import { AccountSwitcher } from "./AccountSwitcher"
import useHttpClient from "@/hook/useHttpClient"
import { useEffect, useState } from "react"
import IResponseHelper from "@/models/response/IResponseHelper"
import { InboxItem, InboxStat } from "@/models/Types"
import { useTranslation } from "react-i18next";

  interface MailProps {
    defaultLayout: number[] | undefined
    defaultCollapsed?: boolean
    navCollapsedSize: number
  }
  
  export function Mail({
    // accounts,
    // mails,
    defaultLayout = [20, 32, 48],
     defaultCollapsed = false,
     navCollapsedSize,
  }: MailProps) {
    //
    const { t, i18n } = useTranslation("", { keyPrefix: "Inbox" });
    //
    const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsed)
    //const [mail] = useMail();


    const { isLoading, request } = useHttpClient();
    const [loaded, setLoaded] = useState(false);

    let mailsInitial: InboxItem[] = [];
    const [mails, setMails] = useState(mailsInitial);

    let mailstatInitial: InboxStat = {  InboxCount: 0, DraftCount: 0, SentCount: 0, CompletedCount: 0, DeletedCount: 0, CancelledCount: 0 };
    const [mailstats, setMailStats] = useState(mailstatInitial);
    //
    useEffect(() => {
      fetch();
      fetchstat();
    }, []);


    const fetch = async () => {
      setLoaded(false);
      const resp = await request<IResponseHelper<InboxItem[]>>(`/api/v1/inbox/getinbox`, "GET");
      if(resp==null) return;
      //const resp = await ScreenService.post(initialscreen);
      if (resp.data) {
        setLoaded(true);
        setMails(resp.data);
        console.log("Inbox", resp.data);  
  
      }
      debugger;
    };

    const fetchstat = async () => {
      setLoaded(false);
      const resp = await request<IResponseHelper<InboxStat>>(`/api/v1/inbox/getinboxstat`, "GET");
      if(resp==null) return;
      //const resp = await ScreenService.post(initialscreen);
      if (resp.data) {
        setMailStats(resp.data); 
        console.log("InboxStats", resp.data);  
      }
      debugger;
    };
    
    return (
      <TooltipProvider delayDuration={0}>
        <ResizablePanelGroup
          direction="horizontal"
          onLayout={(sizes: number[]) => {
            document.cookie = `react-resizable-panels:layout:mail=${JSON.stringify(
              sizes
            )}`
          }}
          className="h-full max-h-[800px] items-stretch"
        >
          {/* 1- Navigation Window */}
          <ResizablePanel
            defaultSize={defaultLayout[0]}
            collapsedSize={navCollapsedSize}
            collapsible={true}
            minSize={15}
            maxSize={20}
            onCollapse={() => {
              setIsCollapsed(true)
              document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                true
              )}`
            }}
            onResize={() => {
              setIsCollapsed(false)
              document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                false
              )}`
            }}
            className={cn(
              isCollapsed &&
                "min-w-[50px] transition-all duration-300 ease-in-out"
            )}
          >
            <div
              className={cn(
                "flex h-[52px] items-center justify-center",
                isCollapsed ? "h-[52px]" : "px-2"
              )}
            >
              <AccountSwitcher isCollapsed={isCollapsed} />
            </div>
            <Separator />
            <Nav
              isCollapsed={isCollapsed}
              links={[
                {
                  title: t("Inbox"),
                  label: mailstats.InboxCount.toString(),
                  icon: Inbox,
                  variant: "default",
                },
                {
                  title:  t("Drafts"),
                  label:  mailstats.DraftCount.toString(),
                  icon: File,
                  variant: "ghost",
                },
                {
                  title: t("Sent"),
                  label: mailstats.SentCount.toString(),
                  icon: Send,
                  variant: "ghost",
                },
                {
                  title:t("Completed"),
                  label: mailstats.CompletedCount.toString(),
                  icon: ArchiveX,
                  variant: "ghost",
                },
                {
                  title: t("Deleted"),
                  label: mailstats.DeletedCount.toString(),
                  icon: Trash2,
                  variant: "ghost",
                },
                {
                  title: t("Cancelled"),
                  label: mailstats.CancelledCount.toString(),
                  icon: Archive,
                  variant: "ghost",
                },
              ]}
            />
            <Separator />
            {/* <Nav
              isCollapsed={isCollapsed}
              links={[
                {
                  title: "Social",
                  label: "972",
                  icon: Users2,
                  variant: "ghost",
                },
                {
                  title: "Updates",
                  label: "342",
                  icon: AlertCircle,
                  variant: "ghost",
                },
                {
                  title: "Forums",
                  label: "128",
                  icon: MessagesSquare,
                  variant: "ghost",
                },
                // {
                //   title: "Shopping",
                //   label: "8",
                //   icon: ShoppingCart,
                //   variant: "ghost",
                // },
                // {
                //   title: "Promotions",
                //   label: "21",
                //   icon: Archive,
                //   variant: "ghost",
                // },
              ]}
            /> */}
          </ResizablePanel>
          <ResizableHandle withHandle />
          {/* 2- Mail List */}
          <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
            <Tabs defaultValue="all">
              <div className="flex items-center px-4 py-2">
                <h1 className="text-xl font-bold">{t("Inbox")}</h1>
                <TabsList className="ml-auto">
                  <TabsTrigger
                    value="all"
                    className="text-zinc-600 dark:text-zinc-200"
                  >
                    {t("All Mails")}
                  </TabsTrigger>
                  <TabsTrigger
                    value="unread"
                    className="text-zinc-600 dark:text-zinc-200"
                  >
                     {t("Unread")} 
                  </TabsTrigger>
                </TabsList>
              </div>
              <Separator />
              <div className="bg-background/95 p-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
                <form>
                  <div className="relative">
                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input placeholder=  {t("Search")} className="pl-8" />
                  </div>
                </form>
              </div>
              <TabsContent value="all" className="m-0">
                <MailList items={mails} />
              </TabsContent>
              <TabsContent value="unread" className="m-0">
                <MailList items={mails.filter((item :any) => !item.read)} />
              </TabsContent>
            </Tabs>
          </ResizablePanel>
          <ResizableHandle withHandle />
          {/* 3- Left Mail Item Detail */}
          <ResizablePanel defaultSize={defaultLayout[2]} minSize={30}>
            {/* <MailDisplay
               mail={mails.find((item : any) => item.id === mail.selected) || null}
            /> */}
          </ResizablePanel>
        </ResizablePanelGroup>
      </TooltipProvider>
    )
  }