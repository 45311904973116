import clsx from "clsx";
import { Label } from "@/shadcn/ui/label";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";
import Item from "@/models/screen/Item";
import {  Constants } from "@/models/Consts";
import { Input } from "@/shadcn/ui/input";

export interface IOrcFile {
  FieldName: string;
  ClassName?: string;
  CaptionClassName?: string;
  Data?: string;
  Disabled? : boolean;
  Required? : boolean;
  HasError?: boolean;
  Caption?: string;
  OnChange?: (_value: string) => void;
  HelpRequested?: (e:any) => void;
}

export default function OrcFile(props: IOrcFile) {
  //
  

  return (
    <div className={clsx("flex flex-col gap-1 w-full")}>
       {props.Caption ? <Label htmlFor={props.FieldName} className={clsx("font-normal", props.CaptionClassName ? props.CaptionClassName : "")}>{props.Caption + (props.Required ? " *" : "")}</Label>: null} 
       <div>
       <input  
            key={props.FieldName} 
            type="file"
            onChange={(e) => props.OnChange ? props.OnChange(e.target.value) : null} 
            className={clsx("rounded-md p-2 text-left border-gray-100 border",
              props.ClassName,
            props.HasError ? "border-red-200 bg-red-50 border-2" : "border-gray-300 border",
            props.Disabled ? Constants.InActive : ""
          )
        }
          disabled={props.Disabled}
          value={props.Data}
            />
       </div>

       
    </div>

          


  );
}
