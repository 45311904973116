import { FieldType } from "../Enums";
import { IScreenField } from "../Interfaces";
import { OrcViewResult } from "../OrcViewResult";
import Item from "./Item";
import OrcProp from "./OrcFieldProp";

// export default class OrcField implements IScreenField {
export default class OrcField {
  FieldName: string = "";
  FieldType: string = "";
  ParentFieldName: string = "#";
  Visible: boolean = true;
  IsFormField: boolean = true;
  Enabled: boolean = true;
  Disabled: boolean = false;
  Required: boolean = true;
  PostBack: boolean = false;
  HasError: boolean = false;
  Seqnr: number = 99;
  ClassName?: string | undefined;
  Caption?: string | undefined;
  MuteText?: string | undefined;
  PlaceHolder?: string | undefined;
  DataElement?: string | undefined;
  Domain?: string | undefined;
  DataType?: string | undefined;
  Length?: number | undefined;
  ScreenName?: string | undefined;
  ScreenProg?: string | undefined;
  CaptionClassName?: string | undefined;
  Items?: Item[] = [];
  Mask?: string | undefined;
  Regex?: string | undefined;
  Data?: any;
  Props: OrcProp[] = [];
  Progname?: string | undefined;
  ViewName?: string | undefined;
  ViewModel?: OrcViewResult | undefined;
  Loading?: boolean = false;
  

}
