import { FieldType, SchemaType, ScreenEvent } from "@/models/Enums";
import { Icon } from "@iconify/react";
import { useContext, useEffect, useState } from "react";
import * as f from "./../comps/Index";
import { OrcScreen } from "@/models/screen/OrcScreen";
import ScreenService from "@/services/program/ScreenService";
import OrcField from "@/models/screen/OrcField";
import { useSearchParams } from "react-router-dom";
import { Separator } from "@/shadcn/ui/separator";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "@/shadcn/ui/use-toast";
import clsx from "clsx";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/shadcn/ui/dialog";
import { Label } from "@/shadcn/ui/label";
import { Input } from "@/shadcn/ui/input";
import { Button } from "@/shadcn/ui/button";
import OrcProp from "@/models/screen/OrcFieldProp";
import { Toaster } from "@/shadcn/ui/toaster";
import { OrcViewResult } from "@/models/OrcViewResult";
import useHttpClient from "@/hook/useHttpClient";
import IResponseHelper from "@/models/response/IResponseHelper";
import { OrcContext } from "@/context/OrcContext";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/shadcn/ui/tooltip";

// const initialchartData = [
//   {
//     "id": "women",
//     "label": "Women",
//     "value": 300
//   },
//   {
//     "id": "men",
//     "label": "Men",
//     "value": 564
//   }

// ]


function DynamicPage() {
  //
  //
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [pagefound, setPageFound] = useState(false);
  const [unAuthorized, setunAuthorized] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { isLoading, request } = useHttpClient();
  //pathname screen adıdır, 10 karakterden sonra başlar, yani /apps/exec dan sonra alınır, eğer exec değişirse mutlaka 10 değiştirilmelidir, /hr/om/analytics
  // const screenname = encodeURIComponent(
  //   window.location.pathname.substring(10).substring(1).toUpperCase()
  // );

 // console.log("window.location", window.location);
  //
 // const [searchParams] = useSearchParams();

  const routeParams = useParams();
  const appname = routeParams.appname;
  // if(appname) {
  //   console.log("appname", appname);
  // }
  //Create Initial Screen Instance
  let initialscreen: OrcScreen = new OrcScreen();
  //initialscreen.Path = screenname;
  const urlParams = new URLSearchParams(window.location.search);
  var app =urlParams.get('app');
  initialscreen.UIPath =  encodeURIComponent(window.location.pathname.toLowerCase());
  //initialscreen.UIPath =  encodeURIComponent(window.location.pathname.toLowerCase());
  initialscreen.HRef = window.location.href;
  initialscreen.UICode = app ? app.toUpperCase() : "";
  initialscreen.ProgName = "";
  initialscreen.ScreenEvent = ScreenEvent.Get;
  let url = new URL(window.location.href);
  //
  let _helpField: OrcField = new OrcField();
  const [helpField, setHelpField] = useState(_helpField);
  //
  debugger;
// for (const [key, value] of url.searchParams) {

// }
  // searchParams.forEach((value, key) => {
  //   console.log(value, key);
  //   initialscreen.Params.push({ Name: key, Value: value });
  //   if (key == "pid") initialscreen.ProgName = value.toUpperCase();
  // });
  const currentContext = useContext(OrcContext);
  const [screen, setScreen] = useState(initialscreen);
  // const [chartdata, setChartData] = useState(initialchartData);
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await request<IResponseHelper<OrcScreen>>(`/api/v1/screen/post`, "POST", initialscreen);
    if(resp==null) return;
    //const resp = await ScreenService.post(initialscreen);
    if (resp.data) {
      setScreen(resp.data);
   //   console.log("Screen", resp.data);
      setunAuthorized(resp.data.UnAuthorized);
      setLoaded(true);
      setPageFound(true);
      //
      if(resp.data.EnableLog) {
        resp.data.Console.map((msg) => console.log(msg));
      }

      if(resp.data.Messages.length>0) {
        var messages = "";
        resp.data.Messages.map((msg) => (messages += msg + "\n"));
        toast({
          title: messages
        });
      }
      //
    } else {
      setLoaded(true);
      //toast.success(resp.Error); // Displays a success message
      setPageFound(false);

      // debugger;
      // alert(resp.error);
    }
    debugger;
  };

  const refreshChartData = () => {
    debugger;
    // const newdata = [ ...initialchartData];
    // newdata[0].value = newdata[0].value + 100;
    // setChartData(newdata);
  }; 

  /*
    user clicks on the code field, it will open the page in a new tab
  */
  const GoToPage = (uicode : string) => {
    debugger;
    window.open("/apps/pages/change/" + uicode);
  }; 

  /*
    user clicks on the code field, it will open the program in a new tab
  */
  const GoToProgram = (progname : string) => {
    debugger;
    window.open("/apps/program/change/" + progname);
  }; 

  const GoToDataElement = (rollname : string) => {
    debugger;
    window.open("/apps/dataelement/change/" + rollname);
  }; 

  const GoToDomain = (domname : string) => {
    debugger;
    window.open("/apps/domain/change/" + domname);
  }; 

  /*
    user clicks on the code field, it will open the program in a new tab
  */
  const DynamicFieldChanged = async (fieldName: string, newData: any) => {
    debugger;
    let newScreen = {...screen};
    var changedField = newScreen.Fields.filter((o) => o.FieldName === fieldName)[0];
    if (changedField) {
      //
      changedField.Data = newData;
      changedField.MuteText = "Bekir Karadeniz";
      //
      if(changedField.PostBack === true) {

        screen.ScreenEvent = ScreenEvent.PostBack;
        screen.PostFieldName = changedField.FieldName;
        //send postback to server
        const resp = await request<IResponseHelper<OrcScreen>>(`/api/v1/screen/post`, "POST", screen);
        if(resp==null) return;
        if (resp.data) {
          if (resp.data.UnAuthorized == true) {
            setunAuthorized(true);
            return;
          }
          setScreen(resp.data);

          if(resp.data.EnableLog) {
            resp.data.Console.map((msg) => console.log(msg));
          }
          if (resp.data.LeaveScreen === true) {
            if (resp.data.NextScreen) navigate(resp.data.NextScreen);
          }
          if(resp.data.Messages.length>0)
          {
            var messages = "";
            resp.data.Messages.map((msg) => (messages += msg + "\n"));
            toast({
              title: messages,
            });
          }
    
    
        }
      }
      else
      {
        setScreen(newScreen);
      }

      
     
    }


  };

  //Any button in screen fires this event
  const DynamicButtonClicked = async (buttonName: string) => {
    debugger;
  //  console.log("DynamicButtonClicked", buttonName);
    let buttonClicked = screen.Fields.filter((o) => o.FieldName == buttonName)[0];
    var navTo = GetPropValue(buttonClicked, "NavigateTo");
    if(navTo) {
      navigate(navTo);
      return;

    }
  //  console.log("DynamicButtonClicked2", buttonName);
    let ScreenHasError : boolean = false;
    let formFields = screen.Fields.filter((o) => o.IsFormField == true);
    for (let i = 0; i < formFields.length; i++) {
     // console.log(formFields[i]);
      //const field = formFields[i];
      if (formFields[i].Required == true) {
        if (formFields[i].Data == null || formFields[i].Data == "") {
          formFields[i].HasError = true;
          ScreenHasError=true;
        } else {
          formFields[i].HasError = false;
        }
      }
    }

    if(ScreenHasError){
      let newScreen = {...screen};
      setScreen(newScreen);
      toast({
        title: "Eksik Bilgi1",
        description: "Lütfen zorunlu alanları doldurunuz.",
      });
      return;
    }


    screen.ScreenEvent = ScreenEvent.Post;
    screen.PostFieldName = buttonName;
    debugger;
    //const resp = await ScreenService.post(screen);
    buttonClicked.Disabled = true;
    buttonClicked.Loading = true;
    const resp = await request<IResponseHelper<OrcScreen>>(`/api/v1/screen/post`, "POST", screen);
    if(resp==null) {
      buttonClicked.Disabled = false;
      buttonClicked.Loading = false;
      return;
    } 
    if (resp.data) {
      if (resp.data.UnAuthorized == true) {
        setunAuthorized(true);
        return;
      }
      let buttonClickedServer = resp.data.Fields.filter((o) => o.FieldName == buttonName)[0];
      buttonClickedServer.Disabled = false;
      buttonClickedServer.Loading = false;
      setScreen(resp.data);


    //  console.log("Screen", resp.data);
      if(resp.data.EnableLog) {
        resp.data.Console.map((msg) => console.log(msg));
      }
      //setLoaded(true);

      if (resp.data.LeaveScreen === true) {
        if (resp.data.NextScreen) navigate(resp.data.NextScreen);
      }
      if(resp.data.Messages.length>0)
      {
        var messages = "";
        resp.data.Messages.map((msg) => (messages += msg + "\n"));
        toast({
          title: messages,
        });
      }


    }


  };




  const OrcViewButtonClicked = async (orcviewname: string, buttonName: string, tableData : any, rowData: any) => {
    debugger;
    screen.ScreenEvent = ScreenEvent.Post;
    screen.PostFieldName = orcviewname;
    screen.PostSubFieldName = buttonName;
    screen.RowData = rowData;
    if(rowData)
      screen.RowKeyClicked = rowData["__RowKey"] ? rowData["__RowKey"] : undefined;

    

    //Incoming data is set to table field Data prop
    var f = screen.Fields.filter((o) => o.FieldName === orcviewname)[0];
    if (f) {
      const fldOrcTable: OrcField = f;
      fldOrcTable.Data = tableData;
    }

   // setLoaded(true);
    debugger;
    const resp = await ScreenService.post(screen);
    if (resp.data) {
      const newScreen : OrcScreen = resp.data;

      //setScreen(newScreen);

      if(resp.data.EnableLog) {
        resp.data.Console.map((msg) => console.log(msg));
      }

      if (resp.data.LeaveScreen === true) {
        if (resp.data.NextScreen) navigate(resp.data.NextScreen);
      }

        if(resp.data.Messages.length>0)
        {
          var messages = "";
          resp.data.Messages.map((msg) => (messages += msg + "\n"));
          toast({
            title: messages,
          });
        }
        

    }




    //alert("OrcViewButtonClicked = " + orcviewname + " " + buttonName + " " + JSON.stringify(data));
  }

  /**
   * This method is triggered when the save button on the table is clicked.
   * It handles the logic for saving the current state of the table.
   */
  const TableSaveClicked = async (fieldName: string, tableData: any) => {
   // console.log("TableSaveClicked2208", fieldName, tableData);
    debugger;
    screen.ScreenEvent = ScreenEvent.Post;
    screen.PostFieldName = fieldName;
    debugger;
    //Incoming data is set to table field Data prop
    var f = screen.Fields.filter((o) => o.FieldName === fieldName)[0];
    if (f) {
      const fldOrcTable: OrcField = f;
      fldOrcTable.Data = tableData;
    }
    //setLoaded(false);
    debugger;
    //let newScreen = {...screen};
    const resp = await ScreenService.post(screen);
    if (resp.data) {
      const newScreen : OrcScreen = resp.data;
      // const newFields = [...newScreen.Fields];
      // newScreen.Fields = newFields;
      setScreen(newScreen);
      // let newScreen = resp.data;
      //console.log("tablokayıttansonragelenekren", newScreen);
      // debugger;
      // setScreen(newScreen);
      
      //fetch();
      //setLoaded(true);
     // console.log("Screen", resp.data);
      if(resp.data.EnableLog) {
        resp.data.Console.map((msg) => console.log(msg));
      }
      //setLoaded(true);

      if (resp.data.LeaveScreen === true) {
        if (resp.data.NextScreen) navigate(resp.data.NextScreen);
      }
      if(resp.data.Messages.length>0)
      {
        var messages = "";
        resp.data.Messages.map((msg) => (messages += msg + "\n"));
        toast({
          title: messages
        });
      }



    }
    debugger;
  };

  const PageNotFoundView = (
    <div className="min-h-[500px] flex flex-col items-center justify-center text-4xl text-primary">
      <div className="font-bold text-6xl mb-4">404</div>
      <div>
        program{" "}aa
        <span className="text-red-600">
          {decodeURIComponent(initialscreen.ProgName.toLowerCase())}
        </span>{" "}
        Not found
      </div>
    </div>
  );

  const UnAuthorizedView = (
    <div className="min-h-[500px] flex flex-col items-center justify-center text-4xl text-primary">
      <div className="font-bold text-6xl mb-4">401</div>
      <div>
        Page{" " + screen.UICode}
        <span className="text-red-600">
          {decodeURIComponent(initialscreen.ProgName.toLowerCase())}
        </span>{" "}
        Not authorized
      </div>
    </div>
  );

  const GotoPage = (lnk: string) => {
    if(lnk.startsWith("http")) {
      window.open(lnk, "_blank");
      return;
    }
    if (lnk == "/logout") {
      currentContext.removeUser();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else navigate(lnk);
    //alert(lnk);
  };

  const AddPageHeader = (title: string, subtitle?: string) => {
    return (
      <div className="w-full hidden md:block">
        <div className="flex flex-row justify-between">
          <div className="">
            {screen.ShowPageTitle && <div className={clsx("text-2xl", screen.PageTitleClassName)}>{title} {screen.ShowPageSubTitle}</div>}
            {screen.ShowPageSubTitle && <div className={clsx("text-xl", screen.PageTitleClassName)}>{subtitle}</div>}
            {screen.ShowBreadCrumb && (
              <Breadcrumb>
                <BreadcrumbList>
                  {screen.BCLinks.map((linkitem, index) => (
                     
                    <BreadcrumbItem key={"breadc_" + index}>
                      {index > 0 && <BreadcrumbSeparator className={clsx("", screen.PageTitleClassName)} />}
                      <BreadcrumbLink
                        className={clsx("hover:cursor-pointer hover:text-gray-200", screen.PageTitleClassName)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(linkitem.Link);
                        }}
                      >
                       
                         { linkitem.Title}
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                  ))}
                  <BreadcrumbSeparator className={clsx("", screen.PageTitleClassName)} />
                  <BreadcrumbItem>
                    <BreadcrumbPage className={clsx("", screen.PageTitleClassName)}>{title}</BreadcrumbPage>
                  </BreadcrumbItem>
                </BreadcrumbList>
              </Breadcrumb>
            )}
          </div>
          <div>
            <div className="text-2xl flex flex-row gap-1">

            {/* <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                <Icon icon="raphael:hammerandscrewdriver" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition me-1",
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")} onClick={(e) => { navigate("/apps/exec/basis/develop") } }/>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Orchestra BDD Environment</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider> */}

            {/* <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                <Icon icon="material-symbols:settings" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition",
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")} onClick={(e) => { navigate("/apps/exec/settings") }}/>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Customizing</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider> */}

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                <Icon icon="ep:refresh-right" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition",
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")} onClick={(e) => fetch()}/>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Refresh Page</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>



            {/* <Icon icon="ep:refresh-right" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition",
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")} onClick={(e) => fetch()}/>  */}


              <Dialog>
                <DialogTrigger asChild>
                  <Icon icon="fontisto:info" width="18" height="18" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition",
                    screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : ""
                  )} />
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px] font-sapFont">
                  <DialogHeader>
                    <DialogTitle>{screen.Title}</DialogTitle>
                    <DialogDescription>
                    {screen.SubTitle}
                    </DialogDescription>
                  </DialogHeader>
                  <hr/>
                  <div className="grid gap-1 py-4">
                  <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="mandt" className="col-span-2 text-left">
                      Client
                    </Label>
                    <span
                      id="mandt"
                      className="col-span-3 p-2 bg-gray-200 hover:cursor-text text-sm rounded-md h-8 text-blue-800 underline"
                    >{screen.Mandt}</span>
                  </div>
                    <div className="grid grid-cols-5 items-center gap-4">
                      <Label htmlFor="uicode" className="col-span-2 text-left">
                        Screen Code
                      </Label>
                      <span
                        id="uicode"
                        className="col-span-3 p-2 bg-gray-200 hover:cursor-text text-sm rounded-md h-8 text-blue-800 underline"
                        onDoubleClick={(e) => GoToPage(screen.UICode)}
                      >{screen.UICode}</span>
                    </div>
                    <div className="grid grid-cols-5 items-center gap-4">
                      <Label htmlFor="prgname" className="col-span-2 text-left">
                        Screen Program
                      </Label>
                      <span
                        id="prgname"
                        className="col-span-3 p-2 bg-gray-200 hover:cursor-text text-sm rounded-md h-8 text-blue-800 underline"
                        onDoubleClick={(e) => GoToProgram(screen.ProgName ? screen.ProgName : "")}
                      >{screen.ProgName}</span>
                    </div>
                    <div className="grid grid-cols-5 items-center gap-4">
                      <Label htmlFor="username" className="col-span-2 text-left">
                        Created By
                      </Label>
                      <span
                        id="crtdby"
                        className="col-span-3 p-2 bg-gray-200 hover:cursor-text rounded-md text-sm h-8"
                      >{screen.CreatedBy?.toUpperCase()}</span>
                    </div>
                    <div className="grid grid-cols-5 items-center gap-4">
                      <Label htmlFor="username" className="col-span-2 text-left">
                        Created On
                      </Label>
                      <span
                        id="crtdby"
                      className="col-span-3 p-2 bg-gray-200 hover:cursor-text rounded-md text-sm h-8"
                      >{screen.CreatedOn}</span>
                    </div>
                    <div className="grid grid-cols-5 items-center gap-4">
                      <Label htmlFor="chngdby" className="col-span-2 text-left">
                        Changed By
                      </Label>
                      <span
                        id="changeddby"
                        className="col-span-3 p-2 bg-gray-200 hover:cursor-text rounded-md text-sm h-8"
                      >{screen.ChangedBy?.toUpperCase()}</span>
                    </div>
                    <div className="grid grid-cols-5 items-center gap-4">
                      <Label htmlFor="username" className="col-span-2 text-left">
                      Changed On
                      </Label>

                                  <span
                        id="changedon"
                      className="col-span-3 p-2 bg-gray-200 hover:cursor-text rounded-md text-sm h-8"
                      >{screen.ChangedOn}</span>
                    </div>
                  </div>
                  {/* <DialogFooter>
                    <Button type="submit">Save changes</Button>
                  </DialogFooter> */}
                </DialogContent>
              </Dialog>
              
              {/* <Icon icon="material-symbols:help-outline" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition",
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")} onClick={(e) => { navigate("/apps/exec/basis/develop") } }/> */}

              {/* <Icon icon="ic:baseline-logout" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition",
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")} onClick={(e) => { GotoPage("/logout") } }/> */}

              {/* <Icon
                icon="carbon:user-avatar-filled"
                width="24"
                height="24"
                className="hover:cursor-pointer"
              /> */}
            </div>
          </div>
        </div>
        {screen.ShowHeaderSeperator && <Separator className="my-1" />}
      </div>
    );
  };



  const AddPageInfo = () => {
    return (
      <div className="w-full">
        <div className="flex flex-row justify-between">
          <div className="">
          </div>
          <div>
            <div className="text-2xl flex flex-row gap-1">
            {/* <Icon icon="raphael:hammerandscrewdriver" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition me-1",
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")} onClick={(e) => { navigate("/apps/exec/basis/develop") } }/>

            <Icon icon="material-symbols:settings" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition",
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")} onClick={(e) => { navigate("/apps/exec/settings") }}/> */}

              <Icon icon="ep:refresh-right" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition", 
                  // IsDarkBG() == true ? "text-white" : "text-black")}
                  screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")}
                  onClick={(e) => fetch()}/>

              <Dialog>
              <DialogTrigger asChild>
                <Icon icon="fontisto:info" width="18" height="18" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition",
                //  IsDarkBG() == true ? "text-white" : "text-black")}
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")}
                />
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px] font-sapFont">
                <DialogHeader>
                  <DialogTitle>{screen.Title}</DialogTitle>
                  <DialogDescription>
                  {screen.SubTitle}
                  </DialogDescription>
                </DialogHeader>
                <hr/>
                <div className="grid gap-1 py-4">
                <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="name" className="col-span-2 text-left">
                      Client
                    </Label>
                    <span
                      id="mandt"
                      className="col-span-3 p-2 bg-gray-200 hover:cursor-text text-sm rounded-md h-8 text-blue-800 underline"
                    >{screen.Mandt}</span>
                  </div>
                  <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="name" className="col-span-2 text-left">
                      Screen Code
                    </Label>
                    <span
                      id="uicode"
                      className="col-span-3 p-2 bg-gray-200 hover:cursor-text text-sm rounded-md h-8 text-blue-800 underline"
                      onDoubleClick={(e) => GoToPage(screen.UICode)}
                    >{screen.UICode}</span>
                  </div>
                  <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="username" className="col-span-2 text-left">
                      Screen Program
                    </Label>
                    <span
                      id="prgname"
                      className="col-span-3 p-2 bg-gray-200 hover:cursor-text text-sm rounded-md h-8 text-blue-800 underline"
                      onDoubleClick={(e) => GoToProgram(screen.ProgName ? screen.ProgName : "")}
                    >{screen.ProgName}</span>
                  </div>
                  <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="username" className="col-span-2 text-left">
                      Created By
                    </Label>
                    <span
                      id="crtdby"
                      className="col-span-3 p-2 bg-gray-200 hover:cursor-text rounded-md text-sm h-8"
                    >{screen.CreatedBy?.toUpperCase()}</span>
                  </div>
                  <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="username" className="col-span-2 text-left">
                      Created On
                    </Label>
                    <span
                      id="crtdby"
                     className="col-span-3 p-2 bg-gray-200 hover:cursor-text rounded-md text-sm h-8"
                    >{screen.CreatedOn}</span>
                  </div>
                  <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="chngdby" className="col-span-2 text-left">
                      Changed By
                    </Label>
                    <span
                      id="changeddby"
                      className="col-span-3 p-2 bg-gray-200 hover:cursor-text rounded-md text-sm h-8"
                    >{screen.ChangedBy?.toUpperCase()}</span>
                  </div>
                  <div className="grid grid-cols-5 items-center gap-4">
                    <Label htmlFor="username" className="col-span-2 text-left">
                    Changed On
                    </Label>

                                <span
                      id="changedon"
                     className="col-span-3 p-2 bg-gray-200 hover:cursor-text rounded-md text-sm h-8"
                    >{screen.ChangedOn}</span>
                  </div>
                </div>
                {/* <DialogFooter>
                  <Button type="submit">Save changes</Button>
                </DialogFooter> */}
              </DialogContent>
              </Dialog>
              
                            {/* <Icon icon="ic:baseline-logout" width="19" height="19" className={clsx("hover:cursor-pointer hover:scale-125 duration-200 transition",
                screen.PageInfoIconsClassName ? screen.PageInfoIconsClassName : "")} onClick={(e) => { GotoPage("/logout") } }/> */}
              {/* <Icon
                icon="carbon:user-avatar-filled"
                width="24"
                height="24"
                className="hover:cursor-pointer"
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const CreateScreen = (fields: BaseField[], pid: string) => {
  //   // return <div>denem</div>;
  //   fields
  //     .filter((o) => o.ParentFieldName == pid)
  //     .map((field: BaseField) => {
  //       if (field.HasChildren) {
  //         const fldcontainer: any = field;
  //         CreateScreen(fldcontainer.Fields, fldcontainer.FieldName);
  //       } else AddField(field);
  //     });

  //   // if (_fields) return _fields;
  //   // else return <div>denem</div>;
  // };

  //Bir alanda F1 e basılırsa..
  const HelpRequested = (e : any, fieldname: string) => {
    debugger;
    e.preventDefault();
    let helpRequestedField = screen.Fields.filter((o) => o.FieldName == fieldname)[0];
    setHelpField(helpRequestedField);
    setOpenModal(true);
   // alert(fieldname);
  };  

  const AddField = (field: any) => {
    const fldBase: OrcField = field;
    switch (fldBase.FieldType.toLowerCase()) {
        case "h1":
        case "header1":
        return (
          <f.OrcHeader1
          key={field.FieldName}
            FieldName={fldBase.FieldName}
            ParentFieldName={fldBase.ParentFieldName}
            ClassName={fldBase.ClassName}
            Text={fldBase.Data}
          />
        );
        case "h2":
        case "header2":
        return (
          <f.OrcHeader2
          key={field.FieldName}
            FieldName={fldBase.FieldName}
            ParentFieldName={fldBase.ParentFieldName}
            ClassName={fldBase.ClassName}
            Text={fldBase.Data}
          />
        );
        case "h3":
        case "header3":
        return (
          <f.OrcHeader3
          key={field.FieldName}
            FieldName={fldBase.FieldName}
            ParentFieldName={fldBase.ParentFieldName}
            ClassName={fldBase.ClassName}
            Text={fldBase.Data}
          />
        );
        case "h4":
        case "header4":
        return (
          <f.OrcHeader4
          key={field.FieldName}
            FieldName={fldBase.FieldName}
            ParentFieldName={fldBase.ParentFieldName}
            ClassName={fldBase.ClassName}
            Text={fldBase.Data}
          />
        );
        case "text":
        case "label":
        case "span":
        case "p":
        return (
          <f.OrcText
          key={field.FieldName}
            FieldName={fldBase.FieldName}
            ParentFieldName={fldBase.ParentFieldName}
            ClassName={fldBase.ClassName}
            Text={fldBase.Data}
          />
        );
        case "div":
        return (
          <f.OrcDiv
          key={field.FieldName}
            FieldName={fldBase.FieldName}
            ParentFieldName={fldBase.ParentFieldName}
            ClassName={fldBase.ClassName}
          >
            {screen.Fields.filter(
              (o) => (o.ParentFieldName == fldBase.FieldName.toLocaleLowerCase() || o.ParentFieldName == fldBase.FieldName.toLocaleUpperCase())  && o.Visible == true
            ).map((field: OrcField) =>
              // field.HasChildren == true ? AddField(field) : AddField(field)
              AddField(field)
            )}
          </f.OrcDiv>
        );
        case "viewm":
        case "cusview":
          return (<f.OrcView
            key={field.FieldName}
          FieldName={fldBase.FieldName}
          ParentFieldName={fldBase.ParentFieldName}
          ClassName={fldBase.ClassName}
          // ViewName={GetPropValue(field, "ViewName")}
          // ViewModel={GetPropValue(field, "ViewModel")} 
          ViewName={field.ViewName}
          ViewModel={field.ViewModel}
          Data={fldBase.Data}
          // ParentScreen={screen}
          //ViewModel={fldBase.Data} 
          TableSaveClicked={(data :any) => TableSaveClicked(fldBase.FieldName, data)}
          ButtonClicked={(buttonname :any, tableData :any, rowData :any) => OrcViewButtonClicked(fldBase.FieldName, buttonname, tableData, rowData)}
          />)

        case "tile":
            return (<f.Tile
              key={field.FieldName}
            FieldName={field.FieldName}
            ClassName={field.ClassName}
            Title={GetPropValue(field, "Title")}
            SubTitle={GetPropValue(field, "SubTitle")}
            IconName={GetPropValue(field, "IconName")}
            IconClassName={GetPropValue(field, "IconClassName")}
            TextClassName={GetPropValue(field, "TextClassName")}
            FigureClassName={GetPropValue(field, "FigureClassName")}
            Figure={GetPropValue(field, "Figure")}
            NavigateTo={GetPropValue(field, "NavigateTo")} />)
        case "hstack":
          let children: React.ReactNode[] = [];
          screen.Fields.filter(
            (o) => (o.ParentFieldName == field.FieldName || o.ParentFieldName == field.FieldName.toLocaleLowerCase() || o.ParentFieldName == field.FieldName.toUpperCase()) && o.Visible == true
          ).map((field: OrcField) => children.push(AddField(field)));
          return (
            <f.HStack
            key={field.FieldName}
              FieldName={field.FieldName}
              ClassName={field.ClassName}
              ParentFieldName={field.ParentFieldName}
            >
              {children}
            </f.HStack>
          );
          case "vstack":
            let children2: React.ReactNode[] = [];
            screen.Fields.filter(
              (o) => (o.ParentFieldName == field.FieldName || o.ParentFieldName == field.FieldName.toLocaleLowerCase() || o.ParentFieldName == field.FieldName.toUpperCase()) && o.Visible == true
            ).map((field: OrcField) => children2.push(AddField(field)));
            return (
              <f.VStack
              key={field.FieldName}
                FieldName={field.FieldName}
                ClassName={field.ClassName}
                ParentFieldName={field.ParentFieldName}
              >
                {children2}
              </f.VStack>
            );
            case "card":
              let children3: React.ReactNode[] = [];
              screen.Fields.filter(
                (o) => (o.ParentFieldName == field.FieldName || o.ParentFieldName == field.FieldName.toLocaleLowerCase() || o.ParentFieldName == field.FieldName.toUpperCase()) && o.Visible == true
              ).map((field: OrcField) => children3.push(AddField(field)));
              return (
                <f.OrcCard
                  key={field.FieldName}
                  FieldName={field.FieldName}
                  ClassName={field.ClassName}
                  ContentClassName={GetPropValue(field, "ContentClassName")}
                  Title={GetPropValue(field, "Title")}
                  SubTitle={GetPropValue(field, "SubTitle")}
                  NavItems={GetPropValue(field, "NavItems")}
                  NavIcon={GetPropValue(field, "NavIcon")} 
                  ShowNav={GetPropValue(field, "ShowNav")}  
                  ShowOkButton={GetPropValue(field, "ShowOkButton")}  
                >
                {children3}
                </f.OrcCard>
              );
          case "piechart":
            return (
              <f.PieChart
              key={field.FieldName}
                FieldName={field.FieldName}
                ClassName={field.ClassName}
                ParentFieldName={field.ParentFieldName}
                Title={GetPropValue(field, "Title")}
                Data={field.Data}
                Refresh={() => refreshChartData()}
              />

            );
          case "donutchart":
            return (
              <f.DonutChart
              key={field.FieldName}
                FieldName={field.FieldName}
                ClassName={field.ClassName}
                ParentFieldName={field.ParentFieldName}
                Title={GetPropValue(field, "Title")}
                Data={field.Data}
                Refresh={() => refreshChartData()}
              />
            );
            case "textbox":
              return (
                <f.OrcTextBox
                key={field.FieldName}
                  FieldName={field.FieldName}
                  ClassName={field.ClassName}
                  Caption={field.Caption}
                  PlaceHolder={field.PlaceHolder}
                  ParentFieldName={field.ParentFieldName}
                  Data={field.Data}
                  Disabled={field.Disabled}
                  HasError={field.HasError}
                  Required={field.Required}
                  CaptionClassName={field.CaptionClassName}
                  Length={field.Length}
                  OnChange={(value) => DynamicFieldChanged(field.FieldName, value)}
                  HelpRequested={(e) => HelpRequested(e,field.FieldName)} 
                />
              );
            case "textarea":
                return (
                  <f.OrcTextArea
                    key={field.FieldName}
                    FieldName={field.FieldName}
                    ClassName={field.ClassName}
                    PlaceHolder={field.PlaceHolder}
                    ParentFieldName={field.ParentFieldName}
                    Caption={field.Caption}
                    Data={field.Data}
                    Disabled={field.Disabled}
                    HasError={field.HasError}
                    Required={field.Required}
                    CaptionClassName={field.CaptionClassName}
                    // Length={field.Length}
                    Rows={GetPropValue(field, "Rows")}
                    OnChange={(value) => DynamicFieldChanged(field.FieldName, value)}
                    HelpRequested={(e) => HelpRequested(e,field.FieldName)} 
                  />
                );
            case "date":
                return (
                  <f.OrcDatePicker
                    key={field.FieldName}
                    FieldName={field.FieldName}
                    ClassName={field.ClassName}
                    ParentFieldName={field.ParentFieldName}
                    Caption={field.Caption}
                    Data={field.Data}
                    Disabled={field.Disabled}
                    HasError={field.HasError}
                    Required={field.Required}
                    CaptionClassName={field.CaptionClassName}
                    // Length={GetPropValue(field, "Length")}
                    OnChange={(value) => DynamicFieldChanged(field.FieldName, value)}
                    HelpRequested={(e) => HelpRequested(e,field.FieldName)} 
                  />
                );
            case "time":
                  return (
                    <f.OrcTimePicker
                      key={field.FieldName}
                      FieldName={field.FieldName}
                      ClassName={field.ClassName}
                      ParentFieldName={field.ParentFieldName}
                      Caption={field.Caption}
                      Data={field.Data}
                      Disabled={field.Disabled}
                      HasError={field.HasError}
                      Required={field.Required}
                      CaptionClassName={field.CaptionClassName}
                      OnChange={(value) => DynamicFieldChanged(field.FieldName, value)}
                     // Length={GetPropValue(field, "Length")}
                      HelpRequested={(e) => HelpRequested(e,field.FieldName)} 
                    />
                  );
            case "select":
              return (
                <f.OrcSelect
                  key={field.FieldName}
                  FieldName={field.FieldName}
                  ClassName={field.ClassName}
                  ParentFieldName={field.ParentFieldName}
                  Caption={field.Caption}
                  Data={field.Data}
                  Disabled={field.Disabled}
                  HasError={field.HasError}
                  Required={field.Required}
                  Items={field.Items}
                  CaptionClassName={field.CaptionClassName}
                  OnSelect={(value) => DynamicFieldChanged(field.FieldName, value)}
                  HelpRequested={(e) => HelpRequested(e,field.FieldName)} 
                />
              );
              case "checkbox":
                return (
                  <f.OrcCheckBox
                    key={field.FieldName}
                    FieldName={field.FieldName}
                    ClassName={field.ClassName}
                    ParentFieldName={field.ParentFieldName}
                    Caption={field.Caption}
                    Data={field.Data}
                    Disabled={field.Disabled}
                    HasError={field.HasError}
                    Required={field.Required}
                    CaptionClassName={field.CaptionClassName}
                    OnChecked={(value) => DynamicFieldChanged(field.FieldName, value)}
                    HelpRequested={(e) => HelpRequested(e,field.FieldName)} 
                  />
                );
              case "button":
                return (
                  <f.OrcButton
                    key={field.FieldName}
                    FieldName={field.FieldName}
                    ClassName={field.ClassName}
                    ParentFieldName={field.ParentFieldName}
                    Caption={field.Caption}
                    Disabled={field.Disabled}
                    Loading={field.Loading}
                    CaptionClassName={field.CaptionClassName}
                    OnClicked={() => DynamicButtonClicked(field.FieldName)}
                  />
                );
              case "orgchart":
                  return (
                    <f.OrcOrg
                      key={field.FieldName}
                      FieldName={field.FieldName}
                      Layout="normal"
                      Scenerio="O-O"
                      Com="NEW"
                      RootOtype={GetPropValue(field, "RootOtype")}
                      RootObjid={GetPropValue(field, "RootObjid")}
                      Plvar={GetPropValue(field, "Plvar")}
                      Disabled={field.Disabled}
                    />
                  );
              case "rimage":
                return (
                  <f.OrcRoundedImage
                    key={field.FieldName}
                    FieldName={field.FieldName}
                    ClassName={field.ClassName}
                    ParentFieldName={field.ParentFieldName}
                    AltText={GetPropValue(field, "AltText")}
                    Src={GetPropValue(field, "Src")}
                  />
                );
                case "cardtable":
                  return (
                    <f.OrcCardTable
                      key={field.FieldName}
                      FieldName={field.FieldName}
                      ClassName={field.ClassName}

                      Title={GetPropValue(field, "Title")}
                      SubTitle={GetPropValue(field, "SubTitle")}
                      Rows={GetPropValue(field, "Rows")}
                      ParentFieldName={field.ParentFieldName}
                    />
                  );
                  case "cardfigure":
                    return (
                      <f.OrcCardFigure
                      key={field.FieldName}
                        FieldName={field.FieldName}
                        ClassName={field.ClassName}
                        Title={GetPropValue(field, "Title")}
                        //Value={GetPropValue(field, "Value")}
                        Value={field.Data}
                        IconName={GetPropValue(field, "IconName")}
                        IconClassName={GetPropValue(field, "IconClassName")}
                        ParentFieldName={field.ParentFieldName}
                      />
                    );
                  case "qrcode":
                    return (
                      <f.OrcQRCode
                        key={field.FieldName}
                        FieldName={field.FieldName}
                        ClassName={field.ClassName}
                        Title={GetPropValue(field, "Title")}
                        Value={field.Data}
                        ParentFieldName={field.ParentFieldName}
                      />
                    );
                    case "somepeople":
                      return (
                        <f.SomePeople
                          key={field.FieldName}
                          FieldName={field.FieldName}
                          ClassName={field.ClassName}
                          Title={GetPropValue(field, "Title")}
                          SubTitle={GetPropValue(field, "SubTitle")}
                          ParentFieldName={field.ParentFieldName}
                        />
                      );
                      case "bchart":
                        return (
                          <f.OrcBarChart
                            key={field.FieldName}
                            Progname={field.Progname}
                          />
                        );
                        case "rchart":
                          return (
                            <f.OrcRadialChart
                              key={field.FieldName}
                              Progname={field.Progname}
                              Value={GetPropValue(field, "Value")} 
                              EndAngle={GetPropValue(field, "EndAngle")} 
                              Units={GetPropValue(field, "Units")} 
                            />
                          );
                          case "pchart":
                            return (
                              <f.OrcPieChart
                                key={field.FieldName}
                                Progname={field.Progname}
                              />
                            );
                            case "file":
                              return (
                                <f.OrcFile
                                  key={field.FieldName}
                                  FieldName={field.FieldName}
                                 

                                />
                              );
                              case "shelp":
                                return (
                                  <f.SHelp
                                    SHName={GetPropValue(field, "SHName")}  
                                    key={field.FieldName}
                                    Data={field.Data}
                                    Caption={field.Caption}
                                    MuteText={field.MuteText}
                                    OnChange={(value) => DynamicFieldChanged(field.FieldName, value)}
                                    //HelpRequested={(e) => HelpRequested(e,field.FieldName)} 
                                    OnSelect={(value) => DynamicFieldChanged(field.FieldName, value)}
                                  />
                                );

      default:
        return (
          <div key={fldBase.FieldName} className="text-red-700">
            {"Field type " + fldBase.FieldType + " not found for " + fldBase.FieldName}{" "}
          </div>
        );
    }
  };

  const GetPropValue = (field: OrcField, propname: string) => {
    var _prop = field.Props.filter((o) => o.PropName == propname);
    if (_prop.length == 1) return _prop[0].PropValue;
    else return null;
  };


  return (
    <>
      {loaded &&
        (pagefound ? (unAuthorized==false ? (
          <>
            <div className={clsx("min-h-svh bg-cover p-4", screen.BGClassName)} 
            style={{ backgroundImage: screen.BGImage ? `url(${screen.BGImage})` : "" }}
            >

              {screen.ShowPageHeader &&
                AddPageHeader(screen.Title, screen.SubTitle)
              }
              {screen.ShowPageHeader === false &&
                AddPageInfo()
              }
              <div className="flex flex-col gap-4 mx-2 w-full ">
                {/* {CreateScreen(screen.Fields, "#")} */}
                {screen.Fields.filter((o) => (o.ParentFieldName == "#" || o.ParentFieldName == "") && o.Visible == true).map(
                  (field: any) => AddField(field)
                )}
              </div>
            </div>
            <Toaster />
            {/* Field F1 Help Dialog */}
            <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
              <DialogContent className="w-full font-sapFont">
                <DialogHeader>
                  <DialogTitle>{ helpField.Caption + " (" +  helpField.FieldName.toUpperCase() + ")" } </DialogTitle>
                  <DialogDescription>
                  { helpField.DataType } { helpField.Length }
                  </DialogDescription>
                </DialogHeader>
                <hr/>
                <div className="flex flex-col gap-1">
                <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Client
                    </div>
                    <div className="">
                    { screen.Mandt }
                    </div>
                  </div>
                <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Field Name
                    </div>
                    <div className="">
                    { helpField.FieldName.toUpperCase() }
                    </div>
                  </div>
                <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Screen Name
                    </div>
                    <div className="">
                    { screen.UICode }
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Screen Program
                    </div>
                    <div className="">
                    <span
                      id="prognamehelp"
                      className="text-blue-800 underline hover:cursor-pointer"
                      onDoubleClick={(e) => GoToProgram(screen.ProgName ? screen.ProgName : "")}
                    >{screen.ProgName}</span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Data Element
                    </div>
                    <div className="">
                    <span
                      id="rollnamehelp"
                      className="text-blue-800 underline hover:cursor-pointer"
                      onDoubleClick={(e) => GoToDataElement(helpField.DataElement ? helpField.DataElement : "")}
                    > {helpField.DataElement }</span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Domain
                    </div>
                    <div className="">
                    <span
                      id="rollnamehelp"
                      className="text-blue-800 underline hover:cursor-pointer"
                      onDoubleClick={(e) => GoToDomain(helpField.Domain ? helpField.Domain : "")}
                    > {helpField.Domain }</span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Data Type
                    </div>
                    <div className="">
                    { helpField.DataType }
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Length
                    </div>
                    <div className="">
                    { helpField.Length }
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Created By
                    </div>
                    <div className="">
                    { screen.CreatedBy?.toLowerCase() }
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Created On
                    </div>
                    <div className="">
                    { screen.CreatedOn }
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Changed By
                    </div>
                    <div className="">
                    { screen.ChangedBy?.toLowerCase() }
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="font-semibold">
                    Changed On
                    </div>
                    <div className="">
                    { screen.ChangedOn }
                    </div>
                  </div>

                </div>
                <DialogFooter className="sm:justify-start">
          <DialogClose asChild>
            <Button type="button" variant="secondary" onClick={() => setOpenModal(false)}>
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
              </DialogContent>
            </Dialog>
          </>
        ) : UnAuthorizedView) : (
          PageNotFoundView
        ))}
      {/* {loaded && !pagefound && <>{PageNotFound}</>} */}
    </>
  );
}

export default DynamicPage;
