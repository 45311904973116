import { Icon } from "@iconify/react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shadcn/ui/dialog";
import { Input } from "@/shadcn/ui/input";
import React, { useState } from "react";
import clsx from "clsx";
import SearchHelpService from "@/services/dd/SearchHelpService";
import SHelpModel from "@/models/shelp/SHelpModel";
import { Label } from "@/shadcn/ui/label";

export interface InputProps
   {
  SHName: string;
  InputClassName?: string;
  OnSelect?: (_value: string) => void;
  CaptionClassName?: string;
  Caption: string;
  MuteText?: string;
  Data: string;
  Disabled? : boolean;
  Required? : boolean;
  HasError?: boolean;
  OnChange?: (_value: string) => void;
}

export default function SHelp(props: InputProps) {
  //
    const [loaded, setLoaded] = useState(false);

    let initialSHelp: SHelpModel = {
      Name: "",
      Title: "",
      Columns: [],
      Data: [],
    };

    const [shelpvalue, setSHelpValue] = useState("");
    const [data, setData] = useState(initialSHelp);

    const [open, setOpen] = React.useState(false);

    const fetch = async () => {
      debugger;
      if(!props.SHName) return;
      setLoaded(false);
     
      // props.SHName
      const resp = await SearchHelpService.get(
        props.SHName,
        props.Data ? props.Data.toString() : ""
      );
      if (resp.data) {
        var millisecondsToWait = 0;
        setTimeout(function () {
          // Whatever you want to do after the wait
          if (resp.data) setData(resp.data);
          setLoaded(true);
        }, millisecondsToWait);
      } else {
        //toast.success(resp.Error); // Displays a success message
        debugger;
        alert(resp.error);
      }
      debugger;
    };

    //

    return (
      <div className={clsx("flex flex-col gap-1 w-full")}>
        {props.Caption ? <Label className={clsx("font-normal", props.CaptionClassName ? props.CaptionClassName : "")}>{props.Caption + (props.Required ? " *" : "")}</Label>: null} 
        <div className="flex flex-row">

       
        <div className={clsx("flex w-40 gap-1 border border-input rounded-lg h-9 items-center")}>
          <Input
            type="text"
            className={clsx(
              "flex h-9 w-full rounded-md text-right border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
              "",
              props.InputClassName ? props.InputClassName : ""
            )}
          // ref={ref}
            value={props.Data}
            onChange={(e) => props.OnChange ? props.OnChange(e.target.value) : null} 
            {...props}
          />
       
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Icon
                icon="ri:file-copy-line"
                width="22"
                height="22"
                className="pe-1 text-gray-600 hover:cursor-pointer"
                onClick={() => fetch()}
              />
            </DialogTrigger>
            {/* <DialogContent className="sm:max-w-1/3 scroll-auto h-96"> */}
            <DialogContent className="h-96 flex flex-col items-start">
              <DialogHeader>
                <DialogTitle>
                  {data.Title + " (" + data.Data?.length.toString() + " records)"}
                </DialogTitle>
                {/* <DialogDescription>
                  Domains created in Data Dictionary.
                </DialogDescription> */}
              </DialogHeader>
            
              {loaded && (
                <div className="overflow-y-scroll h-full w-full">
                {/* <div className="bg-red-100"> */}
                  <table className="table-auto text-left w-full p-4">
                    <thead>
                      <tr>
                        {data.Columns.map((col) => (
                          <th>{col.Title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.Data!.map((item) => (
                        <tr>
                          {data.Columns.map((col, index) => (
                            <td
                              className={clsx(
                                index === 0
                                  ? "underline text-blue-800 hover:cursor-pointer"
                                  : ""
                              )}
                              onClick={() => {
                                if (index === 0) {
                                  props.OnSelect && props.OnSelect(item[col.ColumnName]);
                                  setOpen(false);
                                }
                              }}
                            >
                              {item[col.ColumnName]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* <DialogFooter>
                      <Button type="submit">Save changes</Button>
                    </DialogFooter> */}
            </DialogContent>
          </Dialog>
          
        </div>
        <span className="text-gray-500 font-light ml-1 mt-2 text-sm">{props.MuteText}</span>
        </div>
      </div>
    );
  }




